import { Link } from 'react-router-dom';
import { ArrowSquareOut } from '@phosphor-icons/react';
import { AppRoutes, BillingSettingsNestedRoutes } from 'src/types';
import { useTheme } from 'src/hooks';

const SVG_SIZE = 20;

export const ManagePlanLink = () => {
  const { toggleSettingsPanelExpanded, toggleProfileSettingsBarExpanded } =
    useTheme();

  const handleClick = () => {
    toggleSettingsPanelExpanded(false);
    toggleProfileSettingsBarExpanded(false);
  };

  const SubscriptionURL = `${AppRoutes.MANAGE_ACCOUNT}/${BillingSettingsNestedRoutes.SUBSCRIPTION}`;

  return (
    <div className="nj-section--profile-info-manage-plan">
      <p className="nj-section--profile-info-manage-plan-content">
        <span className="nj-section--profile-info-manage-plan-title">
          Manage plan
        </span>
        <span className="nj-section--profile-info-manage-plan-subtitle">
          Subscription & billing details
        </span>
      </p>

      <Link
        to={SubscriptionURL}
        className="nj-section--profile-info-manage-plan-link"
        onClick={handleClick}
      >
        <ArrowSquareOut size={SVG_SIZE} />
        View
      </Link>
    </div>
  );
};
