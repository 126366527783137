import { PencilSimple } from '@phosphor-icons/react';
import { SVG_SIZE_L } from 'src/constants';
import { useAttachFile } from 'src/hooks';

type EditImageButtonProps = {
  url: string;
};

export const EditImageButton = ({ url }: EditImageButtonProps) => {
  const { sendReceivedFile } = useAttachFile();

  const handleClick = async () => {
    await sendReceivedFile(url);
  };

  return (
    <button
      type="button"
      className="nj-modal-gallery-activity-button"
      onClick={handleClick}
    >
      <PencilSimple size={SVG_SIZE_L} />
    </button>
  );
};
