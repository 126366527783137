import { ReactNode } from 'react';
import {
  ChitChatCard,
  ResearchTaskCreationCard,
  SchedulerCreationCard,
  ImageCard,
} from 'src/types';
import { TaskProgressSection } from './components/TaskProgressSection';

interface ThreadCombinedCardProps {
  data?:
    | ResearchTaskCreationCard
    | SchedulerCreationCard
    | ChitChatCard
    | ImageCard;
  children: ReactNode;
}

export const ThreadCombinedCard = ({
  data,
  children,
}: ThreadCombinedCardProps) => {
  return (
    <div data-e2e="combined-card" className="nj-thread-combined-card">
      <div className="nj-thread-combined-card--container">
        {data && <TaskProgressSection data={data} />}

        {children}
      </div>
    </div>
  );
};
