import { ImageBroken, PencilSimple, Play } from '@phosphor-icons/react';
import Skeleton from 'react-loading-skeleton';
import { useAttachFile, useTheme } from 'src/hooks';
import { Icon } from 'src/components/Icon';
import classNames from 'classnames';
import { MouseEvent, useState } from 'react';
import { AiGenerationStatus } from 'src/types/models/AiGenerationStatus';

const SVG_SIZE = 16;
const SVG_SIZE_BIG = 30;

interface ResearchMediaThumbnailProps {
  thumbnail: string;
  title: string;
  isVideo?: boolean;
  onClick: () => void;
  icon?: string;
  status?: string;
}

export const ResearchMediaThumbnail = ({
  thumbnail,
  title,
  isVideo,
  onClick,
  icon,
  status,
}: ResearchMediaThumbnailProps) => {
  const { isDarkTheme } = useTheme();
  const { sendReceivedFile } = useAttachFile();
  const [isCropped, setIsCropped] = useState(false);

  const { darkTheme: baseDark, lightTheme: baseLight } = {
    darkTheme: '#1d282d',
    lightTheme: '#e8eaea',
  };

  const { darkTheme: highlightDark, lightTheme: highlightLight } = {
    darkTheme: '#11181b',
    lightTheme: '#ffffff',
  };

  const isLoadingImage = thumbnail.length === 0 && !isVideo;

  if (
    isCropped ||
    status === AiGenerationStatus.CONTENT_POLICY_VIOLATION ||
    status === AiGenerationStatus.FAILURE
  ) {
    return (
      <div
        className="nj-thread-research-media--thumbnail placeholder"
        onClick={onClick}
      >
        <span
          className={classNames('nj-thread-research-media--thumbnail-icon', {
            centered: true,
          })}
        >
          <ImageBroken size={SVG_SIZE_BIG} />
        </span>
      </div>
    );
  }

  const handleEditClick = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    await sendReceivedFile(thumbnail);
  };

  return (
    <div className="nj-thread-research-media--thumbnail">
      {isVideo && (
        <span className="nj-thread-research-media--thumbnail-icon">
          <Play size={SVG_SIZE} weight="fill" />
        </span>
      )}
      {icon && (
        <span
          className={classNames('nj-thread-research-media--thumbnail-icon', {
            centered: isLoadingImage,
          })}
        >
          <Icon size={isLoadingImage ? SVG_SIZE_BIG : SVG_SIZE} type={icon} />
        </span>
      )}
      {!isVideo && !isLoadingImage && (
        <button
          type="button"
          className="nj-thread-research-media--edit-button"
          onClick={handleEditClick}
        >
          <PencilSimple size={SVG_SIZE} />
        </button>
      )}
      <div className="nj-gradient-overlay" />
      {isLoadingImage ? (
        <Skeleton
          baseColor={isDarkTheme ? baseDark : baseLight}
          highlightColor={isDarkTheme ? highlightDark : highlightLight}
          duration={2}
          width="100%"
          height="100%"
          count={1}
        />
      ) : (
        <img
          className="nj-thread-research-media--thumbnail-image"
          alt={title}
          src={thumbnail}
          onClick={onClick}
          onError={() => {
            setIsCropped(true);
          }}
        />
      )}
    </div>
  );
};
