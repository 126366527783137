import { useCollapse } from 'react-collapsed';
import { CollapsedButton } from 'src/components/FlatAppearance/components/CollapsedButton';

interface CollapsedItemProps {
  title: string;
  subtitle?: string;
  content: React.ReactNode;
  isExpanded?: boolean;
  valueSubtitle?: string;
  icon?: React.ReactNode;
  onExpand?: (value: string) => void;
  expandedKey?: string;
  header?: React.ReactNode;
  rightSideComponent?: React.ReactNode;
}

export const CollapsedItem = ({
  title,
  subtitle,
  valueSubtitle,
  content,
  isExpanded: isExpandedByDefault,
  icon,
  onExpand,
  expandedKey,
  header,
  rightSideComponent,
}: CollapsedItemProps) => {
  const { getCollapseProps, getToggleProps, isExpanded, setExpanded } =
    useCollapse({ defaultExpanded: isExpandedByDefault });

  return (
    <div className="nj-accordion--panel">
      <div
        className="nj-accordion--header"
        {...getToggleProps({
          onClick: () => {
            if (!!onExpand && !!expandedKey) {
              onExpand(expandedKey || '');
            } else {
              setExpanded((prevExpanded) => !prevExpanded);
            }
          },
        })}
      >
        <div className="nj-accordion--label">
          <div className="nj-accordion--label-content">
            {header ? (
              header
            ) : (
              <>
                <p className="nj-accordion--title">{title}</p>
                {(!!subtitle || !!valueSubtitle) && (
                  <p className="nj-accordion--subtitle">
                    {isExpanded ? subtitle : valueSubtitle}
                  </p>
                )}
              </>
            )}
          </div>
        </div>
        <div className="nj-accordion--label-right-side">
          {!isExpanded && icon}
          {rightSideComponent && rightSideComponent}
          <CollapsedButton isExpanded={isExpanded} />
        </div>
      </div>

      <div {...getCollapseProps()}>
        <div className="nj-accordion--content-wrapper">{content}</div>
      </div>
    </div>
  );
};
