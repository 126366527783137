import { useState, createContext, useCallback } from 'react';
import { AttachmentFileInputId, TAGS_TO_REPLACE } from 'src/constants';

export type ThreadInputBoxContextType = {
  threadInputBoxValue: string;
  threadInputBoxFile: File | null;
  showReplaceTooltip: boolean;
  setThreadInputBoxValue: (value: string) => void;
  setThreadInputBoxFile: (file: File | null) => void;
  onCheckInputTagReplacement: (value: string) => boolean;
  onToggleReplaceTooltip: (value?: boolean) => void;
  deleteAttachment: () => void;
  attachmentLoading: boolean;
  setAttachmentLoading: (value: boolean) => void;
  fileDataToSend: string;
  setFileDataToSend: (value: string) => void;
  threadInputBoxFileError: string;
  setThreadInputBoxFileError: (value: string) => void;
};

type ThreadInputBoxContextProviderProps = {
  children: React.ReactNode;
};

const ThreadInputBoxContext = createContext<ThreadInputBoxContextType>({
  threadInputBoxValue: '',
  threadInputBoxFile: null,
  showReplaceTooltip: false,
  setThreadInputBoxValue: () => undefined,
  setThreadInputBoxFile: () => undefined,
  onCheckInputTagReplacement: () => false,
  onToggleReplaceTooltip: () => undefined,
  deleteAttachment: () => undefined,
  attachmentLoading: false,
  setAttachmentLoading: () => undefined,
  fileDataToSend: '',
  setFileDataToSend: () => undefined,
  threadInputBoxFileError: '',
  setThreadInputBoxFileError: () => undefined,
});

const ThreadInputBoxContextProvider = ({
  children,
}: ThreadInputBoxContextProviderProps) => {
  const [value, setValue] = useState<string>('');
  const [fileDataToSend, setFileDataToSend] = useState<string>('');
  const [file, setFile] = useState<File | null>(null);
  const [showReplaceTooltip, setReplaceTooltip] = useState(false);
  const [attachmentLoading, setAttachmentLoading] = useState(false);
  const [threadInputBoxFileError, setThreadInputBoxFileError] =
    useState<string>('');

  const handleCheckInputTagReplacement = useCallback((value: string) => {
    return TAGS_TO_REPLACE.some((tag) =>
      value.toLocaleLowerCase().includes(tag),
    );
  }, []);

  const handleToggleReplaceTooltip = useCallback(
    (value?: boolean) => {
      setReplaceTooltip(value ?? !showReplaceTooltip);
    },
    [showReplaceTooltip],
  );

  const handleValueChange = useCallback(
    (newValue: string) => {
      setValue(newValue);
      const shouldShowReplaceTooltip = handleCheckInputTagReplacement(newValue);
      if (shouldShowReplaceTooltip) {
        handleToggleReplaceTooltip(true);
      }

      if (showReplaceTooltip && !shouldShowReplaceTooltip) {
        setReplaceTooltip(false);
      }
    },
    [
      showReplaceTooltip,
      handleCheckInputTagReplacement,
      handleToggleReplaceTooltip,
    ],
  );

  const handleDeleteAttachedFile = () => {
    setFile(null);
    setFileDataToSend('');
    setThreadInputBoxFileError('');
    const fileInput = document.getElementById(
      AttachmentFileInputId,
    ) as HTMLInputElement;
    fileInput.value = '';
    fileInput.files = null;
  };

  return (
    <ThreadInputBoxContext.Provider
      value={{
        threadInputBoxValue: value,
        showReplaceTooltip,
        setThreadInputBoxValue: handleValueChange,
        onCheckInputTagReplacement: handleCheckInputTagReplacement,
        onToggleReplaceTooltip: handleToggleReplaceTooltip,
        threadInputBoxFile: file,
        setThreadInputBoxFile: setFile,
        deleteAttachment: handleDeleteAttachedFile,
        attachmentLoading,
        setAttachmentLoading,
        fileDataToSend,
        setFileDataToSend,
        threadInputBoxFileError,
        setThreadInputBoxFileError,
      }}
    >
      {children}
    </ThreadInputBoxContext.Provider>
  );
};

export { ThreadInputBoxContextProvider };
export default ThreadInputBoxContext;
