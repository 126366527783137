import { useTheme } from 'src/hooks';

export const DropAttachmentIcon = () => {
  const { isDarkTheme } = useTheme();
  const COLOR = isDarkTheme ? '#0AA7D9' : '#085DCC';

  return (
    <svg
      width="190"
      height="92"
      viewBox="0 0 190 92"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.2"
        d="M34.6445 35.3568L37.8151 47.1894L49.6477 44.0188L34.6445 35.3568Z"
        fill={COLOR}
      />
      <path
        d="M55.6574 73.2081L25.2307 81.3609C24.7824 81.481 24.3047 81.4181 23.9028 81.186C23.5008 80.954 23.2075 80.5717 23.0874 80.1234L13.1229 42.9353C13.0027 42.487 13.0656 42.0093 13.2977 41.6073C13.5297 41.2054 13.912 40.9121 14.3603 40.792L34.6447 35.3568L49.6479 44.0188L56.8948 71.0648C57.0149 71.5131 56.952 71.9907 56.72 72.3927C56.4879 72.7946 56.1057 73.0879 55.6574 73.2081Z"
        stroke={COLOR}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.6445 35.3568L37.8151 47.1894L49.6477 44.0188"
        stroke={COLOR}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.7002 60.5021L42.2232 56.8787"
        stroke={COLOR}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.5117 67.2636L44.0347 63.6401"
        stroke={COLOR}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.2"
        d="M87.7686 32.8653L101.733 46.8321L108.958 39.6153C109.38 39.1936 109.952 38.9568 110.548 38.9568C111.145 38.9568 111.717 39.1936 112.139 39.6153L123.302 50.7865V16.4571C123.302 15.8604 123.065 15.2881 122.643 14.8661C122.221 14.4442 121.648 14.2071 121.052 14.2071H71.5518C70.955 14.2071 70.3827 14.4442 69.9608 14.8661C69.5388 15.2881 69.3018 15.8604 69.3018 16.4571V48.1512L84.5849 32.8653C84.7938 32.6561 85.042 32.4901 85.3151 32.3769C85.5883 32.2636 85.8811 32.2054 86.1768 32.2054C86.4724 32.2054 86.7652 32.2636 87.0384 32.3769C87.3115 32.4901 87.5597 32.6561 87.7686 32.8653Z"
        fill={COLOR}
      />
      <path
        d="M121.052 14.2071H71.5518C70.3091 14.2071 69.3018 15.2145 69.3018 16.4571V56.9571C69.3018 58.1998 70.3091 59.2071 71.5518 59.2071H121.052C122.294 59.2071 123.302 58.1998 123.302 56.9571V16.4571C123.302 15.2145 122.294 14.2071 121.052 14.2071Z"
        stroke={COLOR}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M104.177 32.2071C106.041 32.2071 107.552 30.6961 107.552 28.8321C107.552 26.9682 106.041 25.4571 104.177 25.4571C102.313 25.4571 100.802 26.9682 100.802 28.8321C100.802 30.6961 102.313 32.2071 104.177 32.2071Z"
        fill={COLOR}
      />
      <path
        d="M101.732 46.8321L108.958 39.6153C109.38 39.1936 109.952 38.9568 110.548 38.9568C111.145 38.9568 111.717 39.1936 112.139 39.6153L123.301 50.7865"
        stroke={COLOR}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.3018 48.1512L84.5849 32.8653C84.7938 32.6561 85.042 32.4901 85.3151 32.3769C85.5883 32.2636 85.8811 32.2054 86.1768 32.2054C86.4724 32.2054 86.7652 32.2636 87.0384 32.3769C87.3115 32.4901 87.5597 32.6561 87.7686 32.8653L114.108 59.2071"
        stroke={COLOR}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.2"
        d="M169.397 45.1533L164.147 54.2465L173.241 59.4965L169.397 45.1533Z"
        fill={COLOR}
      />
      <path
        d="M169.397 45.1533L164.147 54.2465L173.241 59.4965"
        stroke={COLOR}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M160.397 60.7417L162.045 66.8889L155.897 68.536"
        stroke={COLOR}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M152.603 56.2417L146.456 57.8889L148.103 64.036"
        stroke={COLOR}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M159.191 80.8302C159.536 81.0291 159.945 81.083 160.33 80.98C160.714 80.8771 161.042 80.6257 161.241 80.2812L173.241 59.4965L169.397 45.1533L153.809 36.1533C153.464 35.9544 153.055 35.9005 152.671 36.0034C152.286 36.1064 151.959 36.3578 151.76 36.7023L135.26 65.2812C135.061 65.6257 135.007 66.0351 135.11 66.4194C135.213 66.8037 135.464 67.1313 135.809 67.3302L159.191 80.8302Z"
        stroke={COLOR}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
