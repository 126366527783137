import { useRef, useContext, useMemo } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useTheme, useBreakpoint, useOutsideClick } from 'src/hooks';
import { SideSettingsPanel } from '../SidePanel/components/SideSettingsPanel';
import ForwardRefContext from 'src/contexts/ForwardRefContext';

export const MobileSidePanel = () => {
  const { avatarFrameRef } = useContext(ForwardRefContext);

  const settingsRef = useRef<HTMLDivElement>(null);

  const { isMobile, isTablet, isDesktopAndUp } = useBreakpoint();

  const {
    isProfileSettingsBarExpanded,
    isSettingsPanelExpanded,
    toggleProfileSettingsBarExpanded,
    toggleSettingsPanelExpanded,
  } = useTheme();

  const handleOverlayClick = () => {
    if (isDesktopAndUp) {
      return;
    }

    if (isProfileSettingsBarExpanded) {
      toggleProfileSettingsBarExpanded(false);
    }

    if (isSettingsPanelExpanded) {
      toggleSettingsPanelExpanded(false);
    }
  };

  useOutsideClick(settingsRef, handleOverlayClick, avatarFrameRef);

  const animationClassNames = useMemo(() => {
    if (isMobile) {
      return 'nj-animate-fade';
    }
    if (isTablet) {
      return 'nj-animate-horizontal-slow-appearance';
    }
    return 'nj-animate-right-panel-width-reduce';
  }, [isMobile, isTablet]);

  return (
    <CSSTransition
      in={isSettingsPanelExpanded}
      timeout={450}
      classNames={animationClassNames}
      unmountOnExit
      nodeRef={settingsRef}
    >
      <SideSettingsPanel ref={settingsRef} />
    </CSSTransition>
  );
};
