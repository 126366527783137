import { Message, ConversationRole, isChitChatCard } from 'src/types';
import { CommunicationStyle } from 'src/constants';
import { useAvatarPreferences } from 'src/hooks';
import { ResponseTag } from '../ResponseTag';

interface SkillStyleToneTagsProps {
  message: Message;
}

export const SkillStyleToneTags = ({ message }: SkillStyleToneTagsProps) => {
  const { communicationTonesOptions } = useAvatarPreferences();

  const { role, message_id, persona } = message;

  if (role === ConversationRole.USER || !message_id) {
    return null;
  }

  const communicationTone = communicationTonesOptions.find(
    (item) => item.id === persona?.communication_tone_id,
  )?.title;

  return (
    <div className="nj-skill-style-tone-tags">
      {communicationTone && <ResponseTag title={communicationTone} />}

      {persona?.conversational_style && (
        <ResponseTag
          title={
            `Length: ${CommunicationStyle[persona.conversational_style]}` || ''
          }
        />
      )}

      <ResponseTag
        messageType={message.message_type}
        taskSkill={
          isChitChatCard(message.payload)
            ? message.payload.data?.skill
            : undefined
        }
      />
    </div>
  );
};
